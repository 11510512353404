export type InitCommand = {
  action: 'com.iadvize.desk.INIT';
  version: '2.9.0';
};

export type InsertTextInComposeBoxCommand = {
  action: 'com.iadvize.desk.INSERT_TEXT_IN_COMPOSE_BOX';
  correlationId: string;
  content: string;
};

export type LinkAction = {
  type: 'LINK';
  title: string;
  url: string;
};

export type CardImage = {
  url: string;
  description: string;
};

export type CardType = {
  title?: string;
  text?: string;
  image?: CardImage;
  actions: LinkAction[];
};

export type CardBundleType = {
  cards: CardType[];
  title?: string;
};

type PushCardInConversationThreadCommand = {
  action: 'com.iadvize.desk.PUSH_CARD_IN_CONVERSION_THREAD';
  correlationId: string;
  content: CardType;
};

type PushCardBundleInConversationThreadCommand = {
  action: 'com.iadvize.desk.PUSH_CARD_BUNDLE_IN_CONVERSION_THREAD';
  correlationId: string;
  content: CardBundleType;
};

enum ApplePayLineItemType {
  final,
  pending,
}

type PaymentItem = {
  amount: string;
  label: string;
  type: ApplePayLineItemType;
};

type ShippingMethod = {
  amount: string;
  detail: string;
  identifier: string;
  label: string;
};

enum ApplePayContactField {
  email = 'email',
  name = 'name',
  phone = 'phone',
  postalAddress = 'postalAddress',
  phoneticName = 'phoneticName',
}

type ApplePayPaymentRequest = {
  currencyCode: string;
  lineItems: PaymentItem[];
  requiredBillingContactFields: ApplePayContactField[];
  requiredShippingContactFields: ApplePayContactField[];
  shippingMethods: ShippingMethod[];
  total: PaymentItem;
};

export type ApplePayReceivedMessage = {
  type: 'CARD';
  data: CardType;
};

export type ApplePayPaymentRequestType = {
  correlationId: string;
  payment: ApplePayPaymentRequest;
  receivedMessage: ApplePayReceivedMessage;
};

export type ApplePayPaymentCommand = {
  action: 'com.iadvize.desk.PUSH_APPLE_PAY_PAYMENT_REQUEST_IN_CONVERSION_THREAD';
  correlationId: string;
  content: ApplePayPaymentRequestType;
};

type GetJWTCommand = {
  action: 'com.iadvize.desk.GET_JWT';
  correlationId: string;
};

export type Command =
  | InsertTextInComposeBoxCommand
  | InitCommand
  | PushCardInConversationThreadCommand
  | PushCardBundleInConversationThreadCommand
  | GetJWTCommand
  | ApplePayPaymentCommand;

export type CPAInitialised = {
  event: 'com.iadvize.desk.INITIALISED';
  conversationId: string;
  correlationId: string;
  channel: Channel;
  projectId: string;
  language?: string;
};

export type Intent = {
  key: string;
  payload: unknown;
};

export type IntentReceived = {
  event: 'com.iadvize.desk.INTENT_RECEIVED';
  intents: Intent[];
};

export type CpaTriggered = {
  event: 'com.iadvize.desk.CPA_TRIGGERED';
  selectedIntentKeys: string[];
};

export type JWTReceived = {
  event: 'com.iadvize.desk.JWT_RECEIVED';
  correlationId: string;
  jwt: string;
};

export enum ActionStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export type ActionReceived = {
  event: 'com.iadvize.desk.ACTION_RECEIVED';
  correlationId: string;
  status: ActionStatus;
  error?: {
    message: string;
    details?: string[];
  };
};

/** Communication channels supported by iAdvize. (to be replaced by high granularity Channel type) */
// Generated in desk app from GraphQL codegen
export enum Channel {
  /** Apple Business Chat channel */
  AppleBusinessChat = 'APPLE_BUSINESS_CHAT',
  /** Call channel */
  Call = 'CALL',
  /** Chat channel */
  Chat = 'CHAT',
  /** Facebook channel */
  Facebook = 'FACEBOOK',
  /** Facebook Business On Messenger channel */
  FacebookBusinessOnMessenger = 'FACEBOOK_BUSINESS_ON_MESSENGER',
  /** Google Business Messages channel */
  GoogleBusinessMessages = 'GOOGLE_BUSINESS_MESSAGES',
  /** Facebook channel */
  Instagram = 'INSTAGRAM',
  /** Mobile App  channel */
  MobileApp = 'MOBILE_APP',
  /** SMS channel */
  Sms = 'SMS',
  /** Twitter channel */
  Twitter = 'TWITTER',
  /** Video channel */
  Video = 'VIDEO',
  /** WhatsApp channel */
  Whatsapp = 'WHATSAPP',
}

type Context = {
  conversationId: string;
  channel: Channel;
  projectId: string;
  language: string;
};

export interface Client {
  context: Context;
  insertTextInComposeBox: (message: string) => Promise<void>;
  getJWT: () => Promise<string>;
  pushCardInConversationThread: (card: CardType) => Promise<void>;
  pushCardBundleInConversationThread: (
    cardBundle: CardBundleType
  ) => Promise<void>;
  pushApplePayPaymentRequestInConversationThread: (
    applePayPaymentRequest: ApplePayPaymentRequestType
  ) => Promise<void>;
}

type CommandSender = (command: Command) => void;
type EvenReceiver = {
  addEventListener: (listener: (event: any) => void) => () => void;
};

type IntentListener = (intent: Intent[]) => void;

type TriggerListener = (selectedIntentKeys: string[]) => void;

export type Dependencies = {
  commandSender: CommandSender;
  eventReceiver: EvenReceiver;
  onIntent?: IntentListener;
  onTrigger?: TriggerListener;
};

export type MobileEventListener = (message: string) => void;
