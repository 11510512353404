import {
  ActionReceived,
  ActionStatus,
  ApplePayPaymentRequestType,
  CardBundleType,
  CardType,
  Client,
  Command,
  CPAInitialised,
  CpaTriggered,
  Dependencies,
  IntentReceived,
  JWTReceived,
  MobileEventListener,
} from './types';

const targets: string[] = [
  'https://sd.iadvize.com',
  'https://ha.iadvize.com',
  'https://app.iadvize.com',
  'https://ibbu.iadvize.com',
];

let mobileEventListeners: MobileEventListener[] = [];

export const postMessage = (jsonMessage: string) => {
  mobileEventListeners.forEach(listener => listener(jsonMessage));
};

const defaultDependencies: Dependencies = {
  commandSender: (command: Command) => {
    const mobileMsgBus =
      (window as any).iAdvizeAndroidMsgBus ||
      (window as any).webkit?.messageHandlers?.iAdvizeMsgBus;
    if (mobileMsgBus) {
      mobileMsgBus.postMessage(JSON.stringify(command));
    } else {
      targets.forEach(target => {
        window.parent.postMessage(command, target);
      });
    }
  },
  eventReceiver: {
    addEventListener: listener => {
      (window as any).addEventListener('message', listener, false);
      const mobileListener = (message: string) => {
        listener({ data: JSON.parse(message) });
      };
      mobileEventListeners.push(mobileListener);
      const dispose = () => {
        (window as any).removeEventListener('message', listener, false);
        const index = mobileEventListeners.indexOf(mobileListener);
        mobileEventListeners.splice(index, 1);
      };
      return dispose;
    },
  },
};

export const init = ({
  onIntent,
  onTrigger,
  commandSender = defaultDependencies.commandSender,
  eventReceiver = defaultDependencies.eventReceiver,
}: Dependencies = defaultDependencies): Promise<Client> => {
  const clientPromise = new Promise<Client>(resolve => {
    let nextCorrelationId = 1;
    const receiveEvent = (event: any) => {
      const eventType = event.data?.event;
      if (eventType === 'com.iadvize.desk.INITIALISED') {
        const {
          conversationId,
          channel,
          projectId,
          language,
        }: CPAInitialised = event.data as CPAInitialised;
        const correlationId = `corr-${nextCorrelationId++}`;
        const actionReceivedCallback = new Promise<void>(
          (resolveAction, rejectAction) => {
            const dispose = eventReceiver.addEventListener((event: any) => {
              if (event.data?.event === 'com.iadvize.desk.ACTION_RECEIVED') {
                const statusReceived = event.data as ActionReceived;
                if (statusReceived.correlationId === correlationId) {
                  dispose();
                  if (statusReceived.status === ActionStatus.Error) {
                    rejectAction(statusReceived.error);
                  } else {
                    resolveAction();
                  }
                }
              }
            });
          }
        );
        resolve({
          context: {
            conversationId,
            channel,
            projectId,
            language: language || navigator.language, // rfc5646
          },
          insertTextInComposeBox: (message: string) => {
            commandSender({
              correlationId,
              action: 'com.iadvize.desk.INSERT_TEXT_IN_COMPOSE_BOX',
              content: message,
            });

            return actionReceivedCallback;
          },
          pushCardInConversationThread: (card: CardType) => {
            commandSender({
              correlationId,
              action: 'com.iadvize.desk.PUSH_CARD_IN_CONVERSION_THREAD',
              content: card,
            });

            return actionReceivedCallback;
          },
          pushCardBundleInConversationThread: (cardBundle: CardBundleType) => {
            commandSender({
              correlationId,
              action: 'com.iadvize.desk.PUSH_CARD_BUNDLE_IN_CONVERSION_THREAD',
              content: cardBundle,
            });

            return actionReceivedCallback;
          },
          getJWT: () => {
            commandSender({
              action: 'com.iadvize.desk.GET_JWT',
              correlationId,
            });
            return new Promise<string>(resolveJwt => {
              const dispose = eventReceiver.addEventListener((event: any) => {
                if (event.data?.event === 'com.iadvize.desk.JWT_RECEIVED') {
                  const jwtReceived = event.data as JWTReceived;
                  if (jwtReceived.correlationId === correlationId) {
                    dispose();
                    resolveJwt(jwtReceived.jwt);
                  }
                }
              });
            });
          },
          pushApplePayPaymentRequestInConversationThread: (
            applePayPaymentRequest: ApplePayPaymentRequestType
          ) => {
            commandSender({
              action:
                'com.iadvize.desk.PUSH_APPLE_PAY_PAYMENT_REQUEST_IN_CONVERSION_THREAD',
              correlationId,
              content: applePayPaymentRequest,
            });

            return actionReceivedCallback;
          },
        });
      } else if (eventType === 'com.iadvize.desk.INTENT_RECEIVED') {
        const { intents } = event.data as IntentReceived;
        if (onIntent !== undefined) {
          onIntent(intents);
        }
      } else if (eventType === 'com.iadvize.desk.CPA_TRIGGERED') {
        const { selectedIntentKeys } = event.data as CpaTriggered;
        if (onTrigger !== undefined) {
          onTrigger(selectedIntentKeys);
        }
      }
    };
    eventReceiver.addEventListener(receiveEvent);
  });

  commandSender({
    action: 'com.iadvize.desk.INIT',
    version: '2.9.0',
  });

  return clientPromise;
};
